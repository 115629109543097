.form-panel {
    position: absolute;
    left: 20px;
    top: 20px;
    right: 20px;
    background: $c-content-bg;
    padding: 20px;
    max-height: 60%;

    .close {
        position: absolute;
        top: 10px;
        right: 10px;

        svg {
            stroke: $c-content-text;
        }
    }
}

@media screen and (max-width: 950px) {
    .form-panel{
        flex: 1;
        position: relative;
        left: auto;
        top: auto;
        right: auto;
        border-top: 1px solid $c-text-disabled;
        border-bottom: 1px solid $c-text-disabled;
    }

    .playlist + .form-panel {
        border-top: 0;
    }
}
