#nav {
    width: 70px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: $c-bg;

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    li + li {
        margin-top: 10px;
    }

    svg {
        stroke: $c-text-disabled;
        height: 30px;
        width: 30px;

    }

    button:disabled svg {
        stroke: $c-text;
    }

    .logout svg {
        stroke: $c-error;
    }

}

.app #nav + main {
    width: calc(100% - 70px);
    left: 70px;
}

@media screen and (max-width: 950px) {
    #nav {
        top: auto;
        bottom: 0;
        width: 100%;
        height: 50px;
        z-index: 2;

        ul {
            margin: 0;
            flex-direction: row;
            justify-content: center;
            height: 100%;
        }

         li + li {
             margin-top: 0;
             margin-left: 30px;
         }

        svg {
            height: 20px;
            width: 20px;

        }
    }

    .app #nav + main {
        width: 100%;
        height: calc(100% - 50px);
        top: 0;
        left: auto;
    }
}
