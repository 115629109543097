.modal-craft {

    .item {
        clear: both;
        margin-bottom: 20px;
        color: $c-content-text;

        > div > span, h3 {
            display: inline-block;
            margin: 0 10px 0 0;
            font-weight: bold;
            color: $c-yellow;
            font-size: 1.2rem;
        }
    }

}
