
@font-face {
    font-family: 'Lato';
    src: url('../assets/fonts/lato-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

html, body, #app {
    font-family: Lato, Arial, sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 15px;
    overflow: hidden;
}
.scrollable {
    overflow: auto;
}
.scrollable::-webkit-scrollbar {
    width: 5px;
}

.scrollable::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.2);
}

.scrollable::-webkit-scrollbar-thumb {
    background-color: black; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
}

*[id^="view-"] {
    width: 100%;
    height: 100%;
}

h1 {
    color: $c-highlight-bg;
    margin: 0 0 20px;
    font-size: 2rem;
}

h2 {
    padding: 0;
    margin: 0 0 15px;
    color: $c-highlight-bg;
}

form {
    label {
        display: block;
        color: $c-text;
        margin-bottom: 5px;
        font-size: .9rem;
    }


    input {
        background: $c-bg;
        color: $c-text;
        outline: 0;
        border: 0;
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        font-size: 1.1rem;
    }

    input + label,
    input + button,
    input + input[type="submit"] {
        margin-top: 20px;
    }

}

.form-field {
    position: relative;
    display: block;
    float: left;
    clear: both;
    margin-bottom: 20px;

    label {
        height: 15px;
    }

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="date"],
    select {
        width: 100%;
    }

    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        left: -1000px;
        height: 0;
        width: 0;
    }

    input[type="checkbox"] + label {
        position: relative;
        padding-left: 50px;
        height: 30px;
        line-height: 30px;
        vertical-align: middle;
        color: $c-text-disabled;

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 5px;
        }
        &:before {
            width: 35px;
            height: 10px;
            background: $c-bg;
            border-radius: 15px;
            top: 10px;
        }

        &:after {
            width: 20px;
            height: 20px;
            background: lighten($c-bg, 25);
            border-radius: 15px;
        }
    }

    input[type="checkbox"]:checked + label {
        color: $c-text;
        &:after {
            left: 15px;
            background: $c-highlight-bg;
        }
    }


    &.no-clear {
        clear: none;
    }

    &.m-l {
        margin-left: 20px;
    }

    &.m-t {
        margin-top: 20px;
    }

}

button {
    background: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
}

.button,
input[type="submit"] {
    display: block;
    background: $c-highlight-bg;
    color: $c-highlight-fg;
    text-transform: uppercase;
    font-family: Lato, Arial, sans-serif;
    font-weight: bold;
    cursor: pointer;
    height: 40px;

    &.light {
        background: white;
    }
}



ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}

#modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

#modal-wrapper:empty {
    display: none;
}
