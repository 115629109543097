.playlist-create {

    form {
        display: flex;
    }

    .form-field {

        &:first-of-type {
            flex: 1;
        }
    }
}
