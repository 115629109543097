#view-player {

}


@media screen and (max-width: 950px) {
    #view-player {
        display: flex;
        flex-direction: column;
        background: $c-content-bg;
    }
}
