
.app {
    position: relative;
    width: 100%;
    height: 100%;

    > img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    > .credits {
        position: absolute;
        bottom: 10px;
        right: 10px;
        color: white;
        background: rgba(0,0,0,0.5);
        padding: 5px;
        font-size: 12px;
    }

    > main {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 100%;
    }

}
