.playlists {
    position: absolute;
    bottom: 50px;
    left: 20px;
    background: $c-content-bg;
    padding: 20px;
    width: 260px;
    max-height: 50%;
    z-index: 1;
    box-sizing: border-box;

    color: $c-text;

    button {
        color: $c-text;
    }


    &.collapsed {
        ul {
            display: none;
        }
    }

    h2 {
        display: flex;

        .add-playlist {
            margin-left: 20px;
        }
    }

    .top-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $c-content-text;
        border-radius: 50%;
        height: 30px;
        width: 30px;
    }

    .collapse-button {
        position: absolute;
        right: 10px;
        top: 10px;
        border: 0;
        height: 30px;
        width: 30px;
    }

    li {
        display: flex;
        padding: 7px 0 5px;

        svg {
            width: 15px;
            height: 15px;
        }
    }

    li + li {
        border-top: 1px solid  $c-text-disabled;
    }

    span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media screen and (max-width: 950px) {
    .playlists {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        width: 100%;
        max-height: 30%;
        box-sizing: border-box;
        overflow: auto;
    }
}
