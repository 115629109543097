#card-game {
    overflow: hidden;
    padding: 20px;

    .controls {
        position: relative;
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin: 0 0 20px 0;
        padding: 20px;
        background: $c-content-bg;
        color: $c-content-text;
    }

    .cards {
        margin-top: 20px;
    }

    .hand {
        position: absolute;
        bottom: 20px;

    }

    .player-turn {
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 50%;
        position: absolute;
        right: 10px;
        top: 10px;
        background: red;
    }

}
