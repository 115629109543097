#view-login {

    display: flex;
    justify-content: center;
    align-items: center;

    form {
        padding: 30px;
        background: $c-content-bg
    }

}
