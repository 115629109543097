.player-controls {
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient( to top, black, transparent);

    .buttons {
        display: flex;
        flex-direction: row;
    }

    .play-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        background: $c-highlight-bg;
        margin-bottom: 20px;
    }

    &:not(.is-playing) .play-button {
        svg {
            margin-left: 5px;
        }
    }

    .seek-bar {
        position: relative;
        width: 300px;
        height: 5px;
        border-radius: 10px;
        background: $c-content-bg;
        overflow: hidden;

        .thumb {
            position: absolute;

            &:before {
                content: "";
                position: absolute;
                right: 0;
                width: 300px;
                height: 5px;
                background: $c-content-text;
            }
        }
    }

}

@media screen and (max-width: 950px) {
    .player-controls {
        position: relative;
        bottom: auto;
        width: 100%;
        padding-top: 20px;
        justify-content: flex-end;
    }

    .playlists + .player-controls {
        flex: 1;
    }
}
