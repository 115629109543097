.game-card {
    position:relative;
    cursor: pointer;

    width: 150px;
    padding: 10px;
    background: antiquewhite;
    display: inline-block;

    & + .game-card {
        margin-left: 10px;
    }

    .power {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 10px;
        width: 20px;
        height: 20px;
        right: 10px;
        border-radius: 50%;
        border: 2px solid $c-yellow;
    }

    img {
        width: 100%;
    }
}
