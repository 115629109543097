.playlist-edit {
    overflow: hidden;

    ul {
        display: block;
        clear: both;
        background: $c-bg;
        color: $c-content-text;
        margin-bottom: 20px;
        padding: 10px;
        max-height: 150px;
        overflow: auto;
    }

    li  + li {
        margin-bottom: 5px;
    }

    .trash {
        svg {
            stroke: $c-error;
        }
    }

}
