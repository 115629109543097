#character {
    padding: 20px;

    .character-info {
        background: $c-content-bg;
        padding: 20px;
    }

    .crafts {

        color: $c-content-text;

        input[type="number"] {
            width: 20px;
            padding: 2px;
            appearance: none;

            &::-webkit-inner-spin-button {
                display: none;
            }
        }

        .delete svg {
            stroke: $c-error;
        }
    }

}
