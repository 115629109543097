#characters {
    padding: 20px;

    .content {
        background: $c-content-bg;
        padding: 20px;
        color: $c-content-text;
    }

    .content button {
        color: $c-content-text;
    }
}
