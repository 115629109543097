.tooltip {

}

.tooltip-content {
    position: absolute;
    background: $c-bg;
    color: $c-text;
    padding: 5px;
    margin-bottom: 10px;
    font-size: .8rem;
}
