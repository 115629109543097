@font-face {
  font-family: "Lato";
  src: url("lato-regular-webfont.5a20938a.woff2") format("woff2"), url("lato-regular-webfont.9d05b5d3.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
html, body, #app {
  font-family: Lato, Arial, sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 15px;
  overflow: hidden;
}
.scrollable {
  overflow: auto;
}
.scrollable::-webkit-scrollbar {
  width: 5px;
}
.scrollable::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: black;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}
*[id^=view-] {
  width: 100%;
  height: 100%;
}
h1 {
  color: #c7952f;
  margin: 0 0 20px;
  font-size: 2rem;
}
h2 {
  padding: 0;
  margin: 0 0 15px;
  color: #c7952f;
}
form label {
  display: block;
  color: #ffffff;
  margin-bottom: 5px;
  font-size: 0.9rem;
}
form input {
  background: #252525;
  color: #ffffff;
  outline: 0;
  border: 0;
  height: 40px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1.1rem;
}
form input + label,
form input + button,
form input + input[type=submit] {
  margin-top: 20px;
}
.form-field {
  position: relative;
  display: block;
  float: left;
  clear: both;
  margin-bottom: 20px;
}
.form-field label {
  height: 15px;
}
.form-field input[type=text],
.form-field input[type=number],
.form-field input[type=email],
.form-field input[type=password],
.form-field input[type=tel],
.form-field input[type=date],
.form-field select {
  width: 100%;
}
.form-field input[type=checkbox] {
  position: absolute;
  opacity: 0;
  left: -1000px;
  height: 0;
  width: 0;
}
.form-field input[type=checkbox] + label {
  position: relative;
  padding-left: 50px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  color: #998a8a;
}
.form-field input[type=checkbox] + label:before, .form-field input[type=checkbox] + label:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
}
.form-field input[type=checkbox] + label:before {
  width: 35px;
  height: 10px;
  background: #252525;
  border-radius: 15px;
  top: 10px;
}
.form-field input[type=checkbox] + label:after {
  width: 20px;
  height: 20px;
  background: #656565;
  border-radius: 15px;
}
.form-field input[type=checkbox]:checked + label {
  color: #ffffff;
}
.form-field input[type=checkbox]:checked + label:after {
  left: 15px;
  background: #c7952f;
}
.form-field.no-clear {
  clear: none;
}
.form-field.m-l {
  margin-left: 20px;
}
.form-field.m-t {
  margin-top: 20px;
}
button {
  background: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
}
.button,
input[type=submit] {
  display: block;
  background: #c7952f;
  color: #3e392c;
  text-transform: uppercase;
  font-family: Lato, Arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  height: 40px;
}
.button.light,
input[type=submit].light {
  background: white;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
#modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
#modal-wrapper:empty {
  display: none;
}
.app {
  position: relative;
  width: 100%;
  height: 100%;
}
.app > img {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.app > .credits {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  font-size: 12px;
}
.app > main {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 950px) {
  #view-player {
    display: flex;
    flex-direction: column;
    background: #3e392c;
  }
}
.playlists {
  position: absolute;
  bottom: 50px;
  left: 20px;
  background: #3e392c;
  padding: 20px;
  width: 260px;
  max-height: 50%;
  z-index: 1;
  box-sizing: border-box;
  color: #ffffff;
}
.playlists button {
  color: #ffffff;
}
.playlists.collapsed ul {
  display: none;
}
.playlists h2 {
  display: flex;
}
.playlists h2 .add-playlist {
  margin-left: 20px;
}
.playlists .top-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d5cdc7;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.playlists .collapse-button {
  position: absolute;
  right: 10px;
  top: 10px;
  border: 0;
  height: 30px;
  width: 30px;
}
.playlists li {
  display: flex;
  padding: 7px 0 5px;
}
.playlists li svg {
  width: 15px;
  height: 15px;
}
.playlists li + li {
  border-top: 1px solid #998a8a;
}
.playlists span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 950px) {
  .playlists {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    width: 100%;
    max-height: 30%;
    box-sizing: border-box;
    overflow: auto;
  }
}
.modal {
  position: relative;
  background: #3e392c;
  padding: 20px;
}
.modal .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.modal .close svg {
  stroke: white;
  width: 20px;
  height: 20px;
}
.modal p {
  color: #d5cdc7;
  padding: 20px 0;
}
.modal .bottom-buttons {
  display: flex;
  clear: both;
}
.modal .bottom-buttons button {
  flex: 1;
}
.modal .bottom-buttons button:first-of-type {
  margin-right: 5px;
}
.modal .bottom-buttons button:last-of-type {
  margin-left: 5px;
}
.tooltip-content {
  position: absolute;
  background: #252525;
  color: #ffffff;
  padding: 5px;
  margin-bottom: 10px;
  font-size: 0.8rem;
}
.player-controls {
  position: absolute;
  bottom: 0;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to top, black, transparent);
}
.player-controls .buttons {
  display: flex;
  flex-direction: row;
}
.player-controls .play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: #c7952f;
  margin-bottom: 20px;
}
.player-controls:not(.is-playing) .play-button svg {
  margin-left: 5px;
}
.player-controls .seek-bar {
  position: relative;
  width: 300px;
  height: 5px;
  border-radius: 10px;
  background: #3e392c;
  overflow: hidden;
}
.player-controls .seek-bar .thumb {
  position: absolute;
}
.player-controls .seek-bar .thumb:before {
  content: "";
  position: absolute;
  right: 0;
  width: 300px;
  height: 5px;
  background: #d5cdc7;
}

@media screen and (max-width: 950px) {
  .player-controls {
    position: relative;
    bottom: auto;
    width: 100%;
    padding-top: 20px;
    justify-content: flex-end;
  }

  .playlists + .player-controls {
    flex: 1;
  }
}
.playlist {
  background: #3e392c;
  position: absolute;
  right: 20px;
  bottom: 50px;
  z-index: 1;
  padding: 20px;
  width: 300px;
  height: 300px;
  overflow: auto;
}
.playlist button {
  text-align: left;
  color: #998a8a;
}
.playlist .is-active button {
  color: #ffffff;
}

@media screen and (max-width: 950px) {
  .playlist {
    position: relative;
    right: auto;
    bottom: auto;
    flex: 1;
    width: 100%;
    margin: 10px 0;
    padding: 0;
    box-sizing: border-box;
    border-top: 1px solid #998a8a;
    border-bottom: 1px solid #998a8a;
  }
  .playlist ul {
    min-height: 100%;
  }
  .playlist li {
    padding: 10px 0;
  }
  .playlist button {
    padding: 0 10px;
  }
  .playlist:after {
    content: "";
    display: block;
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  }
}
.form-panel {
  position: absolute;
  left: 20px;
  top: 20px;
  right: 20px;
  background: #3e392c;
  padding: 20px;
  max-height: 60%;
}
.form-panel .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.form-panel .close svg {
  stroke: #d5cdc7;
}

@media screen and (max-width: 950px) {
  .form-panel {
    flex: 1;
    position: relative;
    left: auto;
    top: auto;
    right: auto;
    border-top: 1px solid #998a8a;
    border-bottom: 1px solid #998a8a;
  }

  .playlist + .form-panel {
    border-top: 0;
  }
}
.playlist-create form {
  display: flex;
}
.playlist-create .form-field:first-of-type {
  flex: 1;
}
.playlist-edit {
  overflow: hidden;
}
.playlist-edit ul {
  display: block;
  clear: both;
  background: #252525;
  color: #d5cdc7;
  margin-bottom: 20px;
  padding: 10px;
  max-height: 150px;
  overflow: auto;
}
.playlist-edit li + li {
  margin-bottom: 5px;
}
.playlist-edit .trash svg {
  stroke: #e85e5e;
}
#view-login {
  display: flex;
  justify-content: center;
  align-items: center;
}
#view-login form {
  padding: 30px;
  background: #3e392c;
}
#nav {
  width: 70px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #252525;
}
#nav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
#nav li + li {
  margin-top: 10px;
}
#nav svg {
  stroke: #998a8a;
  height: 30px;
  width: 30px;
}
#nav button:disabled svg {
  stroke: #ffffff;
}
#nav .logout svg {
  stroke: #e85e5e;
}

.app #nav + main {
  width: calc(100% - 70px);
  left: 70px;
}

@media screen and (max-width: 950px) {
  #nav {
    top: auto;
    bottom: 0;
    width: 100%;
    height: 50px;
    z-index: 2;
  }
  #nav ul {
    margin: 0;
    flex-direction: row;
    justify-content: center;
    height: 100%;
  }
  #nav li + li {
    margin-top: 0;
    margin-left: 30px;
  }
  #nav svg {
    height: 20px;
    width: 20px;
  }

  .app #nav + main {
    width: 100%;
    height: calc(100% - 50px);
    top: 0;
    left: auto;
  }
}
#card-game {
  overflow: hidden;
  padding: 20px;
}
#card-game .controls {
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  padding: 20px;
  background: #3e392c;
  color: #d5cdc7;
}
#card-game .cards {
  margin-top: 20px;
}
#card-game .hand {
  position: absolute;
  bottom: 20px;
}
#card-game .player-turn {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  background: red;
}
.game-card {
  position: relative;
  cursor: pointer;
  width: 150px;
  padding: 10px;
  background: antiquewhite;
  display: inline-block;
}
.game-card + .game-card {
  margin-left: 10px;
}
.game-card .power {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  width: 20px;
  height: 20px;
  right: 10px;
  border-radius: 50%;
  border: 2px solid #e4934c;
}
.game-card img {
  width: 100%;
}
#character {
  padding: 20px;
}
#character .character-info {
  background: #3e392c;
  padding: 20px;
}
#character .crafts {
  color: #d5cdc7;
}
#character .crafts input[type=number] {
  width: 20px;
  padding: 2px;
  appearance: none;
}
#character .crafts input[type=number]::-webkit-inner-spin-button {
  display: none;
}
#character .crafts .delete svg {
  stroke: #e85e5e;
}
.modal-craft .item {
  clear: both;
  margin-bottom: 20px;
  color: #d5cdc7;
}
.modal-craft .item > div > span, .modal-craft .item h3 {
  display: inline-block;
  margin: 0 10px 0 0;
  font-weight: bold;
  color: #e4934c;
  font-size: 1.2rem;
}
#characters {
  padding: 20px;
}
#characters .content {
  background: #3e392c;
  padding: 20px;
  color: #d5cdc7;
}
#characters .content button {
  color: #d5cdc7;
}
/*# sourceMappingURL=index.f68b2bb6.css.map */
