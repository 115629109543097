.playlist {
    background: $c-content-bg;
    position: absolute;
    right: 20px;
    bottom: 50px;
    z-index: 1;
    padding: 20px;
    width: 300px;
    height: 300px;
    overflow: auto;

    button {
        text-align: left;
        color: $c-text-disabled;
    }


    .is-active button {
        color: $c-text;
    }

}

@media screen and (max-width: 950px) {
    .playlist {
        position: relative;
        right: auto;
        bottom: auto;
        flex: 1;
        width: 100%;
        margin: 10px 0;
        padding: 0;
        box-sizing: border-box;
        border-top: 1px solid $c-text-disabled;
        border-bottom: 1px solid $c-text-disabled;

        ul {
            min-height: 100%;
        }

        li {
            padding: 10px 0;
        }

        button {
            padding: 0 10px;
        }
        &:after {
            content: "";
            display: block;
            position: sticky;
            bottom: 0;
            width: 100%;
            height: 50px;
            background: linear-gradient(to top, rgba(0,0,0,0.5), transparent);
        }
    }
}
