.modal {
    position: relative;
    background: $c-content-bg;
    padding: 20px;

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        svg {
            stroke: white;
            width: 20px;
            height: 20px;
        }
    }

    p {
        color: $c-content-text;
        padding: 20px 0;
    }

    .bottom-buttons {
        display: flex;
        clear: both;

        button {
            flex: 1;
        }

        button:first-of-type {
            margin-right: 5px;
        }

        button:last-of-type {
            margin-left: 5px;
        }
    }
}
